import React from "react";
import Picture from "./Picture";
import CardContainer from "./CardContainer";
import WhatsButton from "./WhatsButton";

const Content = ({ heading1, subtitle, text }) => {
  return (
    <div className="content">
      <Picture />
      <h1>{heading1}</h1>
      <span>{subtitle}</span>
      <p>
        Meu novo site está em desenvolvimento, mas você pode conferir o antigo{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="bloom"
          href="https://flaviaxavier.com/"
        >clicando aqui.</a>
        
        <br /><br />
        Sou uma UX designer e desenvolvedora front-end com profundo interesse
        pela interseção entre design e tecnologia.
        <br /> <br />
        Sendo assim, meu objetivo é criar soluções competitivas e diferenciadas,
        que sejam intuitivas, funcionais e agradáveis aos usuários, baseando-me
        não apenas nas melhores práticas mas também em pesquisas voltadas para o
        contexto de cada projeto, ajudando empresas a potencializar seus
        resultados.
        <br /> <br />
        Atualmente trabalho em full time no meu próprio estúdio (de uma pessoa
        só, rs) de tech & design:{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="bloom"
          href="https://sejabloom.com/"
        >
          {" "}
          Bloom Studio.
        </a>{" "}
        <br /> <br />A seguir você encontra alguns links úteis e meus contatos.
        :)
      </p>
      <CardContainer />
      <WhatsButton
        text="chamar no whatsapp"
        link="https://api.whatsapp.com/send?phone=553897487061&text=Ol%C3%A1!"
      />
    </div>
  );
};

export default Content;
