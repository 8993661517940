import React from "react";

const SocialIcon = ({ icon, link }) => {
  return (
      <a className="social-icon" href={link}>
        <img src={icon} alt="" />
      </a>
  );
};

export default SocialIcon;
