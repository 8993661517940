import "./App.css";
import Content from "./components/Content";
import Footer from "./components/Footer";


function App() {
  return (
    <div className="container">
      <Content
        heading1="Ajudo a construir experiências digitais"
        subtitle={"UX / UI Designer - Desenvolvedora Front-end"}
        
      />
      <Footer/>
    </div>
  );
}

export default App;
