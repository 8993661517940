import React from 'react'
import '../App.css'



const Card = ({icon, name}) => {
  return (
    <div className='card'>
      <img className='card-icon' src={icon} alt="" />
      <h4>{name}</h4>
    </div>
  )
}

export default Card