import React from "react";
import "../App.css";
import SocialIcon from "./SocialIcon";

const Footer = () => {
  return (
    <footer>
      <img src="./fx-logo.svg" alt="" />
      <div className="icon-container">
        <SocialIcon
          link="https://www.behance.net/flavia-xavier"
          icon="./behance.svg"
        />
        <SocialIcon
          link="https://www.instagram.com/flaviaxavierxd/"
          icon="./insta.svg"
        />
        <SocialIcon
          link="https://www.linkedin.com/in/flaviaxavier-90/"
          icon="./linkedin.svg"
        />
        <SocialIcon link="https://github.com/bynhxx" icon="./octoo.svg" />
      </div>
    </footer>
  );
};

export default Footer;
