import React from 'react'
import Card from './Card'
import '../App.css'


const CardContainer = () => {
  return (
    <div className='card-container'>
      <Card name="UX/UI Design" icon="../uxui.svg" />
      <Card name="Web Design" icon="../uxui.svg" />
      <Card name="Desenvolvimento Front-end" icon="../uxui.svg" />
    </div>
  )
}

export default CardContainer